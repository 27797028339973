import HowItWorksSection from 'components/Homepage/HowItWorksSection';
import React from 'react';
import { IGatsbyImage } from 'utils/types';

export interface HowItWorksContainerProps {
  content: {
    title: string;
    headline: string;
    listOfContentWithImages: {
      heading: string;
      description?: {
        description: string;
      };
      image: IGatsbyImage;
    }[];
  };
}

export const HowItWorksContainer: React.FC<HowItWorksContainerProps> = ({
  content,
}) => {
  if (!content) {
    return null;
  }
  const { title, headline, listOfContentWithImages } = content;

  const props = {
    title,
    headline,
    listOfContent: listOfContentWithImages.map((item) => ({
      heading: item.heading,
      description: item.description?.description || '',
      image: item.image.gatsbyImageData,
      imageAlt: item.image.description || '',
    })),
  };

  return <HowItWorksSection {...props} />;
};

export default HowItWorksContainer;
