import {
  typography,
  size,
  mediaQueries,
  InfoBlock as InfoBlockBase,
} from '@everlywell/leaves';
import { animated } from 'react-spring';
import styled from 'styled-components';

import { SectionTitle as RawSectionTitle } from '../../Enterprise/SectionTitle';

export const SectionTitle = styled(RawSectionTitle)`
  text-align: center;
  margin-bottom: ${size.xs1}px;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.sm}px;
  }
`;

const SectionHeadline = styled.h2`
  ${typography.h2Text}
  margin-bottom: ${size.xl1}px;
  text-align: center;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl4}px;
  }
`;

const SectionSubheadline = styled.p`
  ${typography.bodyText}
  margin-bottom: ${size.xl1}px;
  text-align: left;
  ${mediaQueries.forTabletHorizontalUp} {
    text-align: center;
    margin-bottom: ${size.xl4}px;
  }
`;

const InfoBlock = styled(InfoBlockBase).attrs((props) => ({
  imageAlt: props.imageAlt,
}))`
  margin-bottom: ${size.xl1}px;
  background-color: transparent;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
    background-color: transparent;
  }

  > div > div {
    background-color: transparent;
  }
`;

const AnimatedBlockContainer = styled(animated.div)`
  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0 0 33%;
    &:not(:last-child) {
      padding-right: ${size.lg}px;
    }
  }
`;

const InfoBlockContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: transparent;
  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    background-color: transparent;
  }

  ${AnimatedBlockContainer} {
    &:last-child {
      ${InfoBlock} {
        margin-bottom: 0;
      }
    }
  }
`;

export {
  SectionHeadline,
  SectionSubheadline,
  InfoBlockContainer,
  AnimatedBlockContainer,
  InfoBlock,
};
