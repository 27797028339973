import { Row, Col, size, Container } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { GatsbyImage as Img, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { animated as A, useSpring, config } from 'react-spring';

import * as S from './styles';

export type ContentWithImage = {
  heading: string;
  description: string;
  image: IGatsbyImageData;
  imageAlt: string;
};

export interface HowItWorksSectionProps {
  title: string;
  headline: string;
  listOfContent: ContentWithImage[];
}

export const HowItWorksSection: React.FC<HowItWorksSectionProps> = ({
  title,
  headline,
  listOfContent,
}) => {
  // TODO: add 100px offset supported by legacy hook if needed
  const [contentCardsEl, inView] = useInView({ triggerOnce: true });

  // triggered from useChain in enterprise template
  const titleTransition = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : `translateY(${size.xl1}px)`,
    config: config.slow,
  });
  // triggered from useChain in enterprise template
  const displayTransition = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : `translateY(${size.xl2}px)`,
    config: config.slow,
  });
  // triggered from scrolling into view
  const cardsTransition = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : `translateY(${size.xl3}px)`,
    config: config.slow,
  });

  return (
    <SectionWrapper data-testid="howItWorksSection" ref={contentCardsEl}>
      <Container>
        <Row center="xs">
          <Col xs={12} lg={10}>
            <A.div style={titleTransition}>
              <S.SectionTitle text={title} />
            </A.div>
          </Col>
        </Row>
        <Row center="xs">
          <Col xs={12} lg={10}>
            <A.div style={displayTransition}>
              <S.SectionHeadline>{headline}</S.SectionHeadline>
            </A.div>
          </Col>
        </Row>
        <Row center="xs" around="lg">
          <Col xs={12} lg={12}>
            <S.InfoBlockContainer>
              {listOfContent.length &&
                listOfContent.map((content, index) => (
                  <S.AnimatedBlockContainer key={index} style={cardsTransition}>
                    <S.InfoBlock
                      title={content.heading}
                      image={
                        <Img
                          image={content.image}
                          style={{ width: '100%', height: '100%' }}
                          alt={content.imageAlt}
                        />
                      }
                      imageAlt={content.imageAlt}
                      content={content.description}
                      size="small"
                    />
                  </S.AnimatedBlockContainer>
                ))}
            </S.InfoBlockContainer>
          </Col>
        </Row>
      </Container>
    </SectionWrapper>
  );
};

export default HowItWorksSection;
